import base, { polopolyAppMinWidthCasino } from '../layout';

export default {
  ...base,
  textScheme: 'default',
  textNotification: 'alternative',
  inputBg: 'default',
  cashierBg: 'base110',
  appMinWidth: {
    ...base.appMinWidth,
    casino: polopolyAppMinWidthCasino,
  },
  badge: {
    ...base.badge,
    schemes: {
      dreamDropText: 'alternative',
      badgeText: 'default',
    },
  },
  tournamentBar: {
    textScheme: 'default',
  },
  tournamentTooltip: {
    textScheme: 'alternative',
  },
};
